import React, { useState } from 'react';
import { PageProps } from 'gatsby';

import Container from '../components/layout/Container';
import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import SmallContainer from '../components/layout/SmallContainer';
import ForslagImage from '../components/images/ForslagImage';

const Eksempler: React.FunctionComponent<PageProps> = () => {
  return (
    <Layout>
      <MetaData />
      <div className="bg-white">
        <Container className="pt-4 text-center">
          <h1 className="mb-0">Forslag til firmanavne</h1>
          <p className="mt-2">Her får du en række gode eksempler samt links til sider med nyttig information.</p>
          <ForslagImage />
        </Container>
        <SmallContainer className="mt-12 text-content">
          <h3>Gode forslag til firmanavne</h3>
          <p>
            Her få du en liste over gode navne, som andre virksomheder har valgt.
            Så kan du prøve at finde inspiration og tænke over dit eget navn.
          </p>
          <ul>
            <li>
              <p className="mt-1 mb-0 leading-tight">- Nike</p>
            </li>
            <li>
              <p className="mt-1 mb-0 leading-tight">- AirBnB</p>
            </li>
            <li>
              <p className="mt-1 mb-0 leading-tight">- Facebook</p>
            </li>
            <li>
              <p className="mt-1 mb-0 leading-tight">- Google</p>
            </li>
            <li>
              <p className="mt-1 mb-0 leading-tight">- Hotels.com</p>
            </li>
            <li>
              <p className="mt-1 mb-0 leading-tight">- Tripadvisor</p>
            </li>
            <li>
              <p className="mt-1 mb-0 leading-tight">- Coca Cola</p>
            </li>
            <li>
              <p className="mt-1 mb-0 leading-tight">- McDonalds</p>
            </li>
            <li>
              <p className="mt-1 mb-0 leading-tight">- Bestseller</p>
            </li>
          </ul>
          <h3>Få over 500 forslag til dit firmanavn</h3>
          <p>
            En af de letteste muligheder er dog at gå til <a href="https://www.firma-navn.dk">Firmanavn</a>.
            Her får du en generator, som viser dog mere end 500 relevante navne.
            Herefter har du mulighed for at tjekke de individuelle domæner, så du kan finde et godt ledigt navn.
          </p>
        </SmallContainer>
      </div>
    </Layout>
  );
};

export default Eksempler;